import { ActionType, SetCustomerIdAction } from '../../../../src/model/ActionTypes';
import { ActionHandler } from './ActionHandler';
import { CookieStorage } from '../../client/cookie/CookieStorage';

export const CUSTOMER_ID_KEY = 'customerId'

export class SetCustomerIdActionHandler implements ActionHandler {
    public static readonly HANDLER_TYPE = ActionType.SetCustomerId;

    constructor(private readonly cookieStorage: CookieStorage) {}

    public async handleAction(action: SetCustomerIdAction): Promise<void> {
        this.cookieStorage.save(CUSTOMER_ID_KEY, action.payload);
    }
}