import { ElementTransformer } from '../client/dom/ElementTransformer';
import { ApplicationConfig } from '../config/ApplicationConfig';
import { EnvironmentReader } from '../client/environment/EnvironmentReader';
import { BrowserEventListener } from '../client/browser/BrowserEventListener';
import { ActionMessageClient } from '../client/browser/ActionMessageClient';
import { ActionType, ShowChatWindowAction } from '../../../src/model/ActionTypes';
import { ActionHandlerFacade } from './action/ActionHandlerFacade';
import { CustomerEventWatcher } from './event/CustomerEventWatcher';


export class AgentInitiator {
    public static readonly CHAT_WIDGET_IFRAME_ID: string = 'chat-widget-iframe';

    constructor(private readonly elementTransformer: ElementTransformer,
                private readonly environmentReader: EnvironmentReader,
                private readonly eventListener: BrowserEventListener,
                private readonly actionMessageClient: ActionMessageClient,
                private readonly actionHandlerFacade: ActionHandlerFacade,
                private readonly customerEventWatcher: CustomerEventWatcher) {}

    public initiateAgent(): void {
        // Getting `An iframe which has both allow-scripts and allow-same-origin for its sandbox attribute can escape its sandboxing` Error if retrieve ServiceId from initiation
        this.eventListener.addDOMContentLoadListener(async () => {
            const serviceId = await this.environmentReader.getServiceId();
            if (serviceId) {
                await this.initiateAgentCallback();
            }
        })
    }

    private async initiateAgentCallback(): Promise<void> {
        const serviceId = await this.environmentReader.getServiceId();
        this.elementTransformer.addStyleSheetToHead(`${ApplicationConfig.chatWidgetUrl}/addon/addon.css`);
        this.elementTransformer.addIframeToBody(`${ApplicationConfig.chatWidgetUrl}?serviceId=${serviceId}`, AgentInitiator.CHAT_WIDGET_IFRAME_ID);

        await this.actionHandlerFacade.handleAction({
            actionType: ActionType.ShowChatWindow,
            payload: false,
        } as ShowChatWindowAction);

        this.actionMessageClient.addActionMessageListener('AgentInitiator', async (message) => {
            await this.actionHandlerFacade.handleAction(message);
        });

        await this.customerEventWatcher.startWatching(serviceId);
    }
}