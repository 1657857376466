import * as rrweb from 'rrweb';
import { CustomEventType, CustomerEvent } from '../model/CustomerEvent';
import 'url-change-event';

export class SessionRecorder {
    private static initiated = false;
    private static isRecording = false;

    public startRecording(customerEventFunction: (event: CustomerEvent<any>) => void) {
        if(!SessionRecorder.initiated) {
            rrweb.record({
                emit: (event) => {
                    if(SessionRecorder.isRecording) {
                        customerEventFunction(event);
                    }
                },
                sampling: {
                    mousemove: false,
                    mouseInteraction: false,
                    scroll: 10_000, //MS,
                    media: 10_000, //MS,
                    input: 'last'
                }
            });
            this.startURLChangeWatcher();
        }

        SessionRecorder.isRecording = true;
    }

    private startURLChangeWatcher() {
        window.addEventListener('urlchangeevent', () => {
            if (SessionRecorder.isRecording) {
                rrweb.addCustomEvent<{ customType: CustomEventType.VisitPage; data: string}>(CustomEventType.VisitPage, {
                    customType: CustomEventType.VisitPage,
                    data: window.location.pathname
                });
            }
        });
    }
}