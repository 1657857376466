import { ActionHandler } from './ActionHandler';
import { Action, ActionType } from '../../../../src/model/ActionTypes';

export class ActionHandlerFacade {
    public constructor(private readonly actionHandlers: Map<ActionType, ActionHandler>) {}

    public async handleAction(action: Action): Promise<void> {
        const handler = this.actionHandlers.get(action.actionType);
        if (handler) {
            await handler.handleAction(action);
        }
    }
}