export class ElementTransformer {
    public addIframeToBody(src: string, iframeId: string, style?: any): HTMLIFrameElement {
        const iframe = document.createElement('iframe');
        iframe.src = src;
        iframe.id = iframeId;
        if (style) {
            Object.assign(iframe.style, style);
        }

        document.body.appendChild(iframe);

        return iframe
    }

    public addStyleSheetToHead(styleSheetHref: string) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = styleSheetHref;

        document.head.appendChild(link);
    }
}