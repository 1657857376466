import { SessionClient } from '../../client/api/SessionClient';
import { CookieStorage } from '../../client/cookie/CookieStorage';
import { CustomerEvent } from '../../client/model/CustomerEvent';
import { CUSTOMER_ID_KEY } from '../action/SetCustomerIdActionHandler';

export class CustomerEventsReporter {
    constructor(private readonly sessionClient: SessionClient, private readonly cookieStorage: CookieStorage) {}

    public async reportCustomerEvent(serviceId: string, customerEvent: CustomerEvent<any>): Promise<void> {
        const customerId = this.cookieStorage.get(CUSTOMER_ID_KEY);

        if(customerId) {
            await this.sessionClient.sentEvent(serviceId, customerId, customerEvent);
        }
    }

    public async requestToUpdateCustomerGeoLocation(serviceId: string): Promise<void> {
        const customerId = this.cookieStorage.get(CUSTOMER_ID_KEY);

        if(customerId) {
            await this.sessionClient.requestToUpdateCustomerGeoLocation(serviceId, customerId);
        }
    }
}