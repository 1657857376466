import { CustomerEvent } from '../model/CustomerEvent';

export class SessionClient {
    private readonly apiUrl: string;

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    public async sentEvent(serviceId: string, customerId: string, activity: CustomerEvent<any>): Promise<void> {
        await fetch(`${this.apiUrl}/customerEvents`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                serviceId: serviceId,
                customerId: customerId,
                events: [activity],
            }),
        })
    }

    public async requestToUpdateCustomerGeoLocation(serviceId: string, customerId: string): Promise<void> {
        await fetch(`${this.apiUrl}/customerEvent/geoLocation?serviceId=${serviceId}&customerId=${customerId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }
}