export interface SizeStyle {
    width: string;
    height: string;
    margin: string;
    bottom: string;
    right: string;
}

export interface ChatWidgetSizeStyles {
    mobile: {
        open: SizeStyle
        close: SizeStyle
    },
    desktop: {
        open: SizeStyle
        close: SizeStyle
    }
}

export const CHAT_WIDGET_SIZE_STYLES: ChatWidgetSizeStyles = {
    mobile: {
        open: {
            width: '100%',
            height: '100%',
            margin: '0',
            bottom: '0px',
            right: '0px',
        },
        close: {
            width: '70px',
            height: '70px',
            margin: '0',
            bottom: '40px',
            right: '40px',
        }
    },
    desktop: {
        open: {
            width: '400px',
            height: '700px',
            margin: '20px',
            bottom: '20px',
            right: '20px',
        },
        close: {
            width: '80px',
            height: '80px',
            margin: '0',
            bottom: '40px',
            right: '40px',
        }
    }
}