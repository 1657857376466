import { ActionHandler } from './ActionHandler';
import { AgentInitiator } from '../AgentInitiator';
import { SizeStyle } from '../../client/dom/SizeStyle';
import { EnvironmentReader } from '../../client/environment/EnvironmentReader';
import { ActionMessageClient } from '../../client/browser/ActionMessageClient';
import { Action, ActionType } from '../../../../src/model/ActionTypes';

export class MessageActionHandler implements ActionHandler {
    public static readonly HANDLER_TYPE = ActionType.Message;

    constructor(
        private readonly environmentReader: EnvironmentReader,
        private readonly actionMessageClient: ActionMessageClient
    ) {}

    public async handleAction(action: Action): Promise<void> {
        const iframe = document.getElementById(AgentInitiator.CHAT_WIDGET_IFRAME_ID) as HTMLIFrameElement;

        if(iframe && !this.environmentReader.isChatWidgetOpen()) {
            this.resizeIframe(iframe);
            this.actionMessageClient.sendActionMessageToChatWidget(action);
        }
    }

    private resizeIframe(iframe: HTMLIFrameElement): void {
        const currentWidth = parseInt(iframe.style.width, 10) || 0;
        const currentHeight = parseInt(iframe.style.height, 10) || 0;
        const newSize = this.getChatWidgetIframeStyle();
        const newWidth = parseInt(newSize.width, 10) || 0;
        const newHeight = parseInt(newSize.height, 10) || 0;

        iframe.style.width = `${Math.max(currentWidth, newWidth)}px`;
        iframe.style.height = `${Math.max(currentHeight, newHeight)}px`;
    }

    public getChatWidgetIframeStyle(): SizeStyle{
        if (this.environmentReader.isMobile()) {
            return {
                width: `${window.innerWidth}px`, height: '210px', margin: '0', bottom: '40px', right: '40px'
            };
        }

        return {
            width: '400px', height: '210px', margin: '0', bottom: '40px', right: '40px'
        };
    }
}