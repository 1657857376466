import { AgentInitiator } from './AgentInitiator';
import { ClientFactory } from '../client/ClientFactory';
import { ActionHandlerFacade } from './action/ActionHandlerFacade';
import { MessageActionHandler } from './action/MessageActionHandler';
import { ActionType } from '../../../src/model/ActionTypes';
import { SetCustomerIdActionHandler } from './action/SetCustomerIdActionHandler';
import { ActionHandler } from './action/ActionHandler';
import { ChatWidgetShowActionHandler } from './action/ChatWidgetShowActionHandler';
import { SessionRecorder } from '../client/record/SessionRecorder';
import { TimeslotRetriever } from './event/TimeslotRetriever';
import { CustomerEventWatcher } from './event/CustomerEventWatcher';
import { CustomerEventsReporter } from './event/CustomerEventsReporter';
import { GreetingMessageSender } from './event/GreetingMessageSender';

export class HandlerFactory {
    private constructor() {}

    public static createAgentInitiator(): AgentInitiator {
        return new AgentInitiator(ClientFactory.createElementTransformer(), ClientFactory.createEnvironmentReader(),
            ClientFactory.createBrowserEventListener(), ClientFactory.createActionMessageClient(), HandlerFactory.createActionHandlerFacade(),
            HandlerFactory.createVisitorActivityWatcher());
    }

    public static createActionHandlerFacade() {
        return new ActionHandlerFacade(new Map<ActionType, ActionHandler>([
            [MessageActionHandler.HANDLER_TYPE, HandlerFactory.createMessageActionHandler()],
            [SetCustomerIdActionHandler.HANDLER_TYPE, HandlerFactory.createSetCustomerIdActionHandler()],
            [ChatWidgetShowActionHandler.HANDLER_TYPE, HandlerFactory.createChatWidgetShowActionHandler()]
        ]));
    }

    private static createMessageActionHandler(): MessageActionHandler {
        return new MessageActionHandler(
            ClientFactory.createEnvironmentReader(),
            ClientFactory.createActionMessageClient());
    }

    private static createSetCustomerIdActionHandler(): SetCustomerIdActionHandler {
        return new SetCustomerIdActionHandler(ClientFactory.createCookieStorage());
    }

    private static createChatWidgetShowActionHandler(): ChatWidgetShowActionHandler {
        return new ChatWidgetShowActionHandler(ClientFactory.createEnvironmentReader());
    }

    private static createTimeslotRetriever() {
        return new TimeslotRetriever(ClientFactory.createEnvironmentReader(), ClientFactory.createFacadeAPIClient());
    }

    private static createVisitorActivityWatcher() {
        return new CustomerEventWatcher(
            HandlerFactory.createCustomerEventReporter(),
            HandlerFactory.createGreetingMessageSender(),
            HandlerFactory.createSessionRecorder())
    }

    private static createCustomerEventReporter(): CustomerEventsReporter {
        return new CustomerEventsReporter(ClientFactory.createSessionClient(), ClientFactory.createCookieStorage());
    }

    private static createSessionRecorder(): SessionRecorder {
        return new SessionRecorder();
    }

    private static createGreetingMessageSender(): GreetingMessageSender {
        return new GreetingMessageSender(ClientFactory.createFacadeAPIClient(),
            HandlerFactory.createTimeslotRetriever(), ClientFactory.createEnvironmentReader(),
            HandlerFactory.createActionHandlerFacade())
    }
}