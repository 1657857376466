export interface CustomerEvent<T> {
    type: EventType;
    data: T;
    timestamp: number;
    delay?: number;
}

export enum EventType {
    DomContentLoaded = 0,
    Load = 1,
    FullSnapshot = 2,
    IncrementalSnapshot = 3,
    Meta = 4,
    Custom = 5,
    Plugin = 6
}

export interface CustomEventData<T> {
    tag: string;
    payload: {
        customType: CustomEventType;
        data: T;
    }
}

export interface CustomerVisitActivityData extends CustomEventData<string>{
    tag: CustomEventType.VisitPage
    payload: {
        customType: CustomEventType.VisitPage;
        data: string;
    }
}

export enum CustomEventType {
    VisitPage = 'VisitPage',
}