import { EnvironmentReader } from '../../client/environment/EnvironmentReader';
import { FacadeAPIClient } from '../../client/api/FacadeAPIClient';
import { Application, TimeWindow } from '../../client/model/Application';
import { Service } from '../../client/model/Service';

export class TimeslotRetriever {
    private application?: Application;
    private service?: Service;

    constructor(
        private readonly environmentReader: EnvironmentReader,
        private readonly facadeClient: FacadeAPIClient
    ) {}

    public async isInOperationTimeSlot(targetDate: Date): Promise<boolean> {
        await this.loadStatusIfNotLoaded();

        if(this.service?.config.enableChatBotAsDefault === false) {
            return false;
        }

        if(this.application?.operationTimeSlots === undefined ||
            this.application.operationTimeSlots.length === 0) {
            return true;
        }

        for (const timeWindow of this.application.operationTimeSlots) {
            if(this.isDateInTimeSlot(timeWindow, targetDate)) {
                return true;
            }
        }

        return false;
    }

    private isDateInTimeSlot(timeWindow: TimeWindow, targetDate: Date): boolean {
        const startAtBasedOnCurrentDate = new Date(targetDate);
        startAtBasedOnCurrentDate.setHours(timeWindow.startAt.getHours());
        startAtBasedOnCurrentDate.setMinutes(timeWindow.startAt.getMinutes());
        const endAtBasedOnCurrentDate = new Date(targetDate);
        endAtBasedOnCurrentDate.setHours(timeWindow.endAt.getHours());
        endAtBasedOnCurrentDate.setMinutes(timeWindow.endAt.getMinutes());

        return startAtBasedOnCurrentDate.getTime() <= targetDate.getTime() && targetDate.getTime() <= endAtBasedOnCurrentDate.getTime();
    }

    private async loadStatusIfNotLoaded(): Promise<void> {
        if (!this.application) {
            const serviceId = await this.environmentReader.getServiceId();
            this.application = await this.facadeClient.getApplication(serviceId);
        }

        if(!this.service) {
            const serviceId = await this.environmentReader.getServiceId();
            this.service = await this.facadeClient.getService(serviceId);
        }
    }
}