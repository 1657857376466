import { ActionType, ShowChatWindowAction } from '../../../../src/model/ActionTypes';
import { ActionHandler } from './ActionHandler';
import { EnvironmentReader } from '../../client/environment/EnvironmentReader';
import { AgentInitiator } from '../AgentInitiator';
import { CHAT_WIDGET_SIZE_STYLES, SizeStyle } from '../../client/dom/SizeStyle';

export class ChatWidgetShowActionHandler implements ActionHandler {
    public static readonly HANDLER_TYPE = ActionType.ShowChatWindow;

    constructor(private readonly environmentReader: EnvironmentReader) {}

    public async handleAction(action: ShowChatWindowAction): Promise<void> {
        const iframe = document.getElementById(AgentInitiator.CHAT_WIDGET_IFRAME_ID) as HTMLIFrameElement;

        if(!iframe) {
            return;
        }

        const chatWidgetStyle = this.getChatWidgetIframeStyle(action.payload);
        iframe.style.width = chatWidgetStyle.width;
        iframe.style.height = chatWidgetStyle.height;
        iframe.style.margin = chatWidgetStyle.margin;
        iframe.style.bottom = chatWidgetStyle.bottom;
        iframe.style.right = chatWidgetStyle.right;
        iframe.style.boxShadow = action.payload ? '0 0.4rem 0.8rem rgba(0, 0, 0, 0.1)' : 'none';
    }

    public getChatWidgetIframeStyle(show: boolean): SizeStyle{
        if (this.environmentReader.isMobile()) {
            return show ? CHAT_WIDGET_SIZE_STYLES.mobile.open : CHAT_WIDGET_SIZE_STYLES.mobile.close;
        }

        return show ? CHAT_WIDGET_SIZE_STYLES.desktop.open : CHAT_WIDGET_SIZE_STYLES.desktop.close;
    }
}