export enum ActionType {
    None = 'None',
    Message = 'Message',
    ShowChatWindow = 'ShowChatWindow',
    SetCustomerId = 'SetCustomerId',
}

/**
 * Action is concept which is asking agent and ChatWidget to do something.
 * This concept is like a message sending between agent and ChatWidget.
 */
export interface Action {
    actionType: ActionType;
    payload: any;
}

export interface MessageActionType extends Action {
    actionType: ActionType.Message;
    payload: string;
}

export interface ShowChatWindowAction extends Action {
    actionType: ActionType.ShowChatWindow;
    payload: boolean;
}

export interface SetCustomerIdAction extends Action {
    actionType: ActionType.SetCustomerId;
    payload: string;
}