import { SessionRecorder } from '../../client/record/SessionRecorder';
import {
    CustomEventData,
    CustomEventType,
    CustomerEvent,
    CustomerVisitActivityData,
    EventType,
} from '../../client/model/CustomerEvent';
import { GreetingMessageSender } from './GreetingMessageSender';
import { CustomerEventsReporter } from './CustomerEventsReporter';

export class CustomerEventWatcher {
    constructor(private readonly customerEventsReporter: CustomerEventsReporter,
                private readonly greetingMessageSender: GreetingMessageSender,
                private readonly sessionRecorder: SessionRecorder) {}

    public async startWatching(serviceId: string): Promise<void> {
        this.greetingMessageSender.sendGreetingMessage(serviceId);
        this.reportInitiateEvent(serviceId);

        this.sessionRecorder.startRecording(async (activity: CustomerEvent<any>) => {
            if (activity.type === EventType.Custom.valueOf() &&
                (activity.data as CustomEventData<any>).payload.customType === CustomEventType.VisitPage.valueOf()) {
                this.greetingMessageSender.sendGreetingMessage(serviceId);
                this.customerEventsReporter.reportCustomerEvent(serviceId, activity);
            }
        });
    }

    private async reportInitiateEvent(serviceId: string): Promise<void>  {
        await this.customerEventsReporter.reportCustomerEvent(serviceId, {
            type: EventType.Custom,
            data: {
                tag: CustomEventType.VisitPage,
                payload: {
                    customType: CustomEventType.VisitPage,
                    data: window.location.pathname,
                }
            } as CustomerVisitActivityData,
            timestamp: Date.now(),
        });
        await this.customerEventsReporter.requestToUpdateCustomerGeoLocation(serviceId);
    }
}