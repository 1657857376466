import { ApplicationConfig } from '../../config/ApplicationConfig';
import { Action } from '../../../../src/model/ActionTypes';
import { AgentInitiator } from '../../handler/AgentInitiator';

export type ActionListenerId = string;

export class ActionMessageClient {
    private static actionListenerIds: Set<ActionListenerId> = new Set<ActionListenerId>();

    public sendActionMessageToChatWidget(action: Action): void {
        const iframe = document.getElementById(AgentInitiator.CHAT_WIDGET_IFRAME_ID) as HTMLIFrameElement;

        if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage(action, '*');
        }
    }

    public addActionMessageListener(actionListenerId: ActionListenerId, listenerFunction: (action: Action) => void ): void {
        if (!ActionMessageClient.actionListenerIds.has(actionListenerId)) {
            window.addEventListener('message', (event: MessageEvent<Action>) => {
                if(this.isActionMessage(event)) {
                    listenerFunction(event.data);
                }
            });

            this.removeEventListenerWhenUnloaded('message', listenerFunction);

            ActionMessageClient.actionListenerIds.add(actionListenerId);
        }
    }

    private removeEventListenerWhenUnloaded(type: string, eventListener: any): void {
        window.addEventListener('unload', () => {
            window.removeEventListener(type, eventListener);
        });
    }

    private isActionMessage(event: MessageEvent<Action>): boolean {
        return !!event.data && !!event.data.actionType && event.origin === ApplicationConfig.chatWidgetUrl;
    }
}