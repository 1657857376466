import { EnvironmentReader } from './environment/EnvironmentReader';
import { BrowserEventListener } from './browser/BrowserEventListener';
import { ElementTransformer } from './dom/ElementTransformer';
import { SessionClient } from './api/SessionClient';
import { ApplicationConfig } from '../config/ApplicationConfig';
import { FacadeAPIClient } from './api/FacadeAPIClient';
import { CookieStorage } from './cookie/CookieStorage';
import { ActionMessageClient } from './browser/ActionMessageClient';
import { SessionRecorder } from './record/SessionRecorder';

export class ClientFactory {
    private constructor() {}

    public static createEnvironmentReader(): EnvironmentReader {
        return new EnvironmentReader(ClientFactory.createCookieStorage());
    }

    public static createCookieStorage(): CookieStorage {
        return new CookieStorage();
    }

    public static createBrowserEventListener(): BrowserEventListener {
        return new BrowserEventListener();
    }

    public static createElementTransformer(): ElementTransformer {
        return new ElementTransformer();
    }

    public static createSessionClient(): SessionClient {
        return new SessionClient(ApplicationConfig.apiUrl);
    }

    public static createFacadeAPIClient(): FacadeAPIClient {
        return new FacadeAPIClient(ApplicationConfig.apiUrl);
    }

    public static createActionMessageClient(): ActionMessageClient {
        return new ActionMessageClient();
    }

    public static createSessionRecorder() {
        return new SessionRecorder();
    }
}