import { AgentInitiator } from '../../handler/AgentInitiator';
import { CHAT_WIDGET_SIZE_STYLES } from '../dom/SizeStyle';
import { ApplicationConfig } from '../../config/ApplicationConfig';
import { CookieStorage } from '../cookie/CookieStorage';
import { v4 as uuidv4 } from 'uuid';

export class EnvironmentReader {
    private static readonly SESSION_ID_KEY = 'sessionId';

    constructor(private readonly cookieStorage: CookieStorage) {
    }

    public async getServiceId(): Promise<string> {
        const garamService = (window as any).garamService;
        if (garamService.serviceId) {
            return garamService.serviceId;
        }

        let siteDomain;

        if (garamService.shopifyDomain) {
            siteDomain = garamService.shopifyDomain;
        }

        if (garamService.siteDomain) {
            siteDomain = garamService.siteDomain;
        }

        return (await (await fetch(`${ApplicationConfig.apiUrl}/service?siteDomain=${siteDomain}`)).json())['id']
    }

    public getSessionId(): string {
        const sessionId = this.cookieStorage.get(EnvironmentReader.SESSION_ID_KEY);

        if (sessionId) {
            return sessionId;
        }

        return this.cookieStorage.saveSession(EnvironmentReader.SESSION_ID_KEY, uuidv4());
    }

    public isMobile(): boolean {
        return /Mobi|Android/i.test(navigator.userAgent);
    }

    public isChatWidgetOpen(): boolean {
        const iframe = document.getElementById(AgentInitiator.CHAT_WIDGET_IFRAME_ID) as HTMLIFrameElement;

        return [CHAT_WIDGET_SIZE_STYLES.mobile.open, CHAT_WIDGET_SIZE_STYLES.desktop.open]
            .some(sizeStyles => JSON.stringify({
                width: sizeStyles.width,
                height: sizeStyles.height,
            }) === JSON.stringify({
                width: iframe.style.width,
                height: iframe.style.height,
            }));
    }
}