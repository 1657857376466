import { FacadeAPIClient } from '../../client/api/FacadeAPIClient';
import { TimeslotRetriever } from './TimeslotRetriever';
import { EnvironmentReader } from '../../client/environment/EnvironmentReader';
import { Action } from '../../../../src/model/ActionTypes';
import { ActionHandlerFacade } from '../action/ActionHandlerFacade';

export class GreetingMessageSender {
    constructor(private readonly apiClient: FacadeAPIClient,
                private readonly timeslotRetriever: TimeslotRetriever,
                private readonly environmentReader: EnvironmentReader,
                private readonly actionHandlerFacade: ActionHandlerFacade,
    ) {}

    public async sendGreetingMessage(serviceId: string): Promise<void> {
        if(!this.environmentReader.isChatWidgetOpen() && (await this.timeslotRetriever.isInOperationTimeSlot(new Date()))) {
            const action = await this.getGreetingMessage(serviceId);
            await this.actionHandlerFacade.handleAction(action);
        }
    }

    private async getGreetingMessage(serviceId: string): Promise<Action> {
        return await this.apiClient.getGreetingMessage(serviceId, this.environmentReader.getSessionId(), {
            screenContent: 'Visitor is still active',
        });
    }
}