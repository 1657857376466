import { Application, TimeWindow } from '../model/Application';
import { Service } from '../model/Service';
import { VisitorContext } from '../model/VisitorContext';
import { Action, ActionType } from '../../../../src/model/ActionTypes';

export class FacadeAPIClient {
    constructor(private readonly apiUrl: string) {}

    public async getApplication(serviceId: string): Promise<Application> {
        const applications = await (await fetch(`${this.apiUrl}/applications?serviceId=${serviceId}`)).json();

        if(applications.length === 0) {
            throw new Error('No application found');
        }

        return {
            ...applications[0],
            operationTimeSlots: this.convertOperationTimeSlots(applications[0].operationTimeSlots),
        };
    }

    public async getService(serviceId: string): Promise<Service> {
        return  await (await fetch(`${this.apiUrl}/service/${serviceId}`)).json();
    }

    public async getGreetingMessage(serviceId: string, sessionId: string, context: VisitorContext): Promise<Action> {
        const response = await (await fetch(`${this.apiUrl}/agent/knockingOnVisitor`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                serviceId: serviceId,
                sessionId: sessionId,
                screenContent: context.screenContent,
            }),
        })).json() as {abilityType: string;message: string;};

        return {
            actionType: ActionType.Message,
            payload: response.message,
        }
    }

    private convertOperationTimeSlots(timeSlots: {startAt: string, endAt: string}[]): TimeWindow[] {
        return timeSlots.map(timeSlot => {
            return {
                startAt: new Date(timeSlot.startAt),
                endAt: new Date(timeSlot.endAt),
            };
        });
    }
}